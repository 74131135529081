import './plans.scss';
import routes from '../../../../navigation/routes';
import basketTypes from '../../../../redux/workers/basket/basket-types';
import actionsType from '../../../../redux/workers/main-page/actions-type';
import { addToBasket, getBasketItems } from '../../../../shared/basketActions';
import BasketSuccessPopup from '../basket-success-popup/basket-success-popup';
import Card from '../card/card';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PlanCard from '../plan-card/plan-card';
import { createDispatchLog } from '../../../../redux/workers/conversion-tracking';
import { ConversionTrackingActionNames } from '../../../../types/conversionTrackingActionNames';
import {
  PlanFilterPageType,
  useFilteredPlansByPlaceType,
} from '../../../../hooks/useFilteredPlansByPlaceType';

const Plans = (props) => {
  const {
    wrapperClass,
    change,
    onChange,
    collapsible,
    currentPlanId,
    planPageType,
    landingPageId = null,
    onlyShowLandingPagePlans = false,
    hideTabs = false,
  } = props;
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const wrapper = classNames(wrapperClass, 'plans__cards');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planType, setPlanType] = useState('DEFAULT');
  const filteredPlans = useFilteredPlansByPlaceType({
    pageType: planPageType,
    planType,
    landingPageId,
    onlyShowLandingPagePlans,
  });
  const generalSettings = useSelector((state) => state.generalSettingsReducer.generalSettings);

  const clickCard = (id) => {
    dispatch({ type: actionsType.LOAD_CURRENT_PLAN, id });
    navigate(`${routes.tariff}/${id}`);
  };

  const handleBasketAddition = (id) => {
    if (id) {
      addToBasket(id);
      setShowSuccessPopup(true);
      dispatch({
        type: basketTypes.BASKET_UPDATE,
        payload: { totalCount: getBasketItems().length },
      });
      dispatch(
        createDispatchLog(ConversionTrackingActionNames.BASKET, {
          actionDataType: 'BASKET_UPDATE',
          data: {
            items: getBasketItems(),
          },
        })
      );
    }
  };

  const renderCards = () =>
    (filteredPlans || []).map((plan) => {
      return (
        <PlanCard
          key={plan.id}
          plan={plan}
          disable={currentPlanId === plan.id}
          onBuyClick={() => {
            if (change) {
              onChange(plan.id);
            } else {
              handleBasketAddition(plan.id);
            }
          }}
        />
      );
    });

  function renderPlans() {
    if (!generalSettings) {
      return null;
    }

    if (generalSettings.pauseSimSell && planPageType !== PlanFilterPageType.CHANGE_PLAN) {
      return (
        <div className={wrapper}>
          <h3 className="plans-coming-soon">COMING SOON...</h3>
        </div>
      );
    }

    return <div className={wrapper}>{renderCards()}</div>;
  }

  return (
    <>
      {showSuccessPopup && (
        <BasketSuccessPopup
          close={() => setShowSuccessPopup(false)}
          onContinue={() => {
            setShowSuccessPopup(false);
          }}
        />
      )}
      {!hideTabs ? (
        <div className="plan-type-tabs">
          <div
            className={`plan-type-tab-item ${planType === 'DEFAULT' ? 'active' : ''}`}
            onClick={() => setPlanType('DEFAULT')}
          >
            SafeSim™
          </div>
          <div
            className={`plan-type-tab-item ${planType === 'ONLY_DATA' ? 'active' : ''}`}
            onClick={() => setPlanType('ONLY_DATA')}
          >
            SafeSim™ Data/Tablet
          </div>
        </div>
      ) : null}
      {renderPlans()}
    </>
  );
};

export default Plans;
