/* eslint-disable max-len */
import routes from './routes';
import HistoryCalls from '../elements/components/account/history-calls/history-calls';
import HistoryPayment from '../elements/components/account/history-payment/history-payment';
import HistorySms from '../elements/components/account/history-sms/history-sms';
import ResetPasswordForm from '../elements/components/reset-password-form/reset-password-form';
import ResetPasswordSuccess from '../elements/components/reset-password-success/reset-password-success';
import RestorePasswordForm from '../elements/components/restore-password-form/restore-password-form';
import SignInForm from '../elements/components/sign-in-form/sign-in-form';
import AccountEditAddYubikey from '../elements/containers/account/edit/add-yubikey/add-yubikey';
import AccountEmailVerificationSuccessful from '../elements/containers/account/edit/email-successful/email-successful';
import AccountEditVerificationEmail from '../elements/containers/account/edit/verification-email/verification-email';
import AccountEditYubikeySuccessful from '../elements/containers/account/edit/yubikey-successful/yubikey-successful';
import Bag from '../elements/containers/bag/bag';
import BillingDetails from '../elements/containers/billing-details/billing-details';
import Blog from '../elements/containers/blog/blog';
import BlogDetail from '../elements/containers/blog-detail/blog-detail';
import Payment from '../elements/containers/payment/payment';
import RestorePasswordEmail from '../elements/containers/restore-password-email/restore-password-email';
import RestorePassword from '../elements/containers/restore-password/restore-password';
import SignIn from '../elements/containers/sign-in/sign-in';
import SignUp from '../elements/containers/sign-up/sign-up';
import AccountLayout from '../elements/layouts/account-layout/account-layout';
import BaseLayout from '../elements/layouts/base-layout/base-layout';
import AccountScreenDelivery from '../elements/screens/account-screen/account-screen-delivery/account-screen-delivery';
import AccountScreenEdit from '../elements/screens/account-screen/account-screen-edit/account-screen-edit';
import AccountScreenHistory from '../elements/screens/account-screen/account-screen-history/account-screen-history';
import AccountScreenMyPlan from '../elements/screens/account-screen/account-screen-my-plan/account-screen-my-plan';
import AccountScreenTariffInfo from '../elements/screens/account-screen/account-screen-tarif-info/account-screen-tariff-info';
import CardTariff from '../elements/screens/card-tariff/card-tariff';
import Linxdot from '../elements/screens/card-linxdot/card-linxdot';
import Main from '../elements/screens/main-page/main';
import NotFoundScreen from '../elements/screens/not-found-screen/not-found-screen';
import PlansScreen from '../elements/screens/plans-screen/plans-screen';
import authTypes from '../redux/workers/auth/auth-types';
import RequireAuth from '../shared/hocs/RequireAuth';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import SetupIntentResult from '../elements/screens/setup-intent-result/setup-intent-result';
import OuterActivation from '../elements/screens/outer-activation/outer-activation';
import generalSettingsTypes from '../redux/workers/general-settings/general-settings-types';
import Checkout from '../elements/containers/checkout/checkout';
import TmoCoverageForApp from '../elements/screens/tmo-coverage-for-app/tmo-coverage-for-app';
import StaticPage from '../elements/screens/static-pages/static-page';
import AccountEditAddOtpVerification from '../elements/containers/account/edit/otp-verification/otp-verification';
import AccountEditOtpVerificationSuccessful from '../elements/containers/account/edit/otp-verification-successful/otp-verification-successful';
import SignInMultiFactor from '../elements/components/sign-in-multi-factor/sign-in-multi-factor';
import PaymentSuccess from '../elements/components/payment-success/payment-success';
import LandingPages from '../elements/screens/landing-pages/landing-pages';
import { ConversionTrackingActionNames } from '../types/conversionTrackingActionNames';
import { createDispatchLog } from '../redux/workers/conversion-tracking';
import { getBasketItems } from '../shared/basketActions';
import AccountScreenOldCustomer from '../elements/screens/account-screen/account-screen-old-customer/account-screen-old-customer';
import HardwaresListingPage from '../elements/screens/hardwares/listing-page/listing-page';
import HardwaresDetailPage from '../elements/screens/hardwares/detail-page/detail-page';
import PrivateWireless from '../elements/screens/private-wireless/private-wireless';
import AccountScreenHardwareDetail from '../elements/screens/account-screen/account-screen-hardware-detail/account-screen-hardware-detail';
import Affirm from '../elements/components/affirm/affirm';

TagManager.initialize({
  gtmId: 'GTM-NH8F8RN',
});

const AppRouter = ({ history }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state) => state.authReducer.isSignedIn);
  window.dataLayer.push({
    event: 'pageview',
  });

  useEffect(() => {
    dispatch({ type: authTypes.CHECK_AUTH });

    if (isSignedIn) {
      dispatch({
        type: authTypes.SIGN_IN_SUCCESS,
        payload: JSON.parse(localStorage.getItem('got-user')),
      });
    }
  }, [isSignedIn, dispatch]);

  useEffect(() => {
    dispatch({ type: generalSettingsTypes.GET_GENERAL_SETTINGS });
  }, []);

  useEffect(() => {
    localStorage.setItem('user_referrer', document.referrer);
  }, []);

  useEffect(() => {
    let actionData = null;

    if (location.pathname.indexOf(routes.checkout) > -1) {
      actionData = {
        actionDataType: 'CHECKOUT_BASKET',
        data: {
          items: getBasketItems(),
        },
      };
    }

    dispatch(createDispatchLog(ConversionTrackingActionNames.PAGE_CHANGE, actionData));
  }, [location]);

  return (
    <BaseLayout>
      <Routes>
        <Route history={history} exact path={routes.home} element={<Main />} />
        <Route history={history} exact path={routes.signUp} element={<SignUp />} />
        <Route history={history} path={routes.signIn.base} element={<SignIn />}>
          <Route history={history} index element={<SignInForm />} />
          <Route
            history={history}
            path={routes.signIn.multiFactor}
            element={<SignInMultiFactor />}
          />
        </Route>
        <Route history={history} path={routes.restorePassword.base} element={<RestorePassword />}>
          <Route history={history} index element={<RestorePasswordForm />} />
          <Route
            history={history}
            path={routes.restorePassword.email}
            element={<RestorePasswordEmail />}
          />
          <Route
            history={history}
            path={routes.restorePassword.reset}
            element={<ResetPasswordForm />}
          />
          <Route
            history={history}
            path={`${routes.restorePassword.reset}/:type`}
            element={<ResetPasswordSuccess />}
          />
        </Route>
        <Route history={history} path={routes.tariff} element={<CardTariff />}>
          <Route history={history} path=":id" element={<CardTariff />} />
        </Route>
        <Route history={history} exact path={routes.bag} element={<Bag />} />
        <Route history={history} exact path={routes.billingDetails} element={<BillingDetails />} />
        <Route history={history} exact path={routes.faq} element={<StaticPage />} />
        <Route history={history} exact path={routes.works} element={<StaticPage />} />
        <Route history={history} exact path={routes.worksNew} element={<StaticPage />} />
        <Route history={history} exact path={routes.payment} element={<Payment />} />
        <Route history={history} exact path={routes.checkout} element={<Checkout />} />
        <Route history={history} exact path={routes.plans} element={<PlansScreen />} />
        <Route
          history={history}
          path={`${routes.account.base}`}
          element={
            <RequireAuth>
              <AccountLayout />
            </RequireAuth>
          }
        >
          <Route history={history} path={routes.account.edit.base} element={<AccountScreenEdit />}>
            <Route
              history={history}
              path={routes.account.edit.registerYubikeyDevice}
              element={<AccountEditAddYubikey />}
            />
            <Route
              history={history}
              path={routes.account.edit.yubikeyRegistrationSuccessful}
              element={<AccountEditYubikeySuccessful />}
            />
            <Route
              history={history}
              path={routes.account.edit.verificationEmailSuccessful}
              element={<AccountEmailVerificationSuccessful />}
            />
            <Route
              history={history}
              path={routes.account.edit.verificationEmailCode}
              element={<AccountEditVerificationEmail />}
            />
            <Route
              history={history}
              path={routes.account.edit.registerOtp}
              element={<AccountEditAddOtpVerification />}
            />
            <Route
              history={history}
              path={routes.account.edit.registerOtpSuccessful}
              element={<AccountEditOtpVerificationSuccessful />}
            />
          </Route>
          <Route
            history={history}
            path={routes.account.tariffInfo}
            element={<AccountScreenTariffInfo />}
          />
          <Route
            history={history}
            path={routes.account.tracker}
            element={<AccountScreenDelivery />}
          >
            <Route history={history} path=":type" element={<AccountScreenDelivery />} />
          </Route>
          <Route history={history} path={routes.account.myPlan} element={<AccountScreenMyPlan />} />
          <Route
            history={history}
            path={routes.account.oldCustomer}
            element={<AccountScreenOldCustomer />}
          />
          <Route
            history={history}
            path={routes.account.hardwares}
            element={<AccountScreenHardwareDetail />}
          />
          <Route
            history={history}
            path={routes.account.history.base}
            element={<AccountScreenHistory />}
          >
            <Route
              history={history}
              path={routes.account.history.calls}
              element={<HistoryCalls />}
            />
            <Route
              history={history}
              path={routes.account.history.payment}
              element={<HistoryPayment />}
            />
            <Route history={history} path={routes.account.history.sms} element={<HistorySms />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundScreen />} />
        <Route history={history} exact path={routes.termsConditions} element={<StaticPage />} />
        <Route history={history} exact path={routes.support} element={<StaticPage />} />
        <Route history={history} exact path={routes.privacy} element={<StaticPage />} />
        <Route history={history} exact path={routes.returnPolicy} element={<StaticPage />} />
        <Route history={history} exact path={routes.acceptable} element={<StaticPage />} />
        <Route history={history} exact path={routes.protect} element={<StaticPage />} />
        <Route
          history={history}
          exact
          path={routes.setupIntentResult}
          element={<SetupIntentResult />}
        />
        <Route
          history={history}
          exact
          path={routes.outerActivation}
          element={<OuterActivation />}
        />
        <Route history={history} path={`${routes.blog}/:slug`} element={<BlogDetail />} />
        <Route history={history} path={routes.blog} element={<Blog />} />
        <Route history={history} path={routes.linxdot} element={<Linxdot />} />
        <Route history={history} path={routes.tmoCoverageForApp} element={<TmoCoverageForApp />} />
        <Route history={history} path={routes.paymentSuccess} element={<PaymentSuccess />} />
        <Route history={history} path={`${routes.landingPage}/:slug`} element={<LandingPages />} />
        <Route
          history={history}
          path={`${routes.hardwares.base}${routes.hardwares.cellPhones}`}
          element={<HardwaresListingPage />}
        />
        <Route
          history={history}
          path={`${routes.hardwares.base}${routes.hardwares.cellPhoneDetail}`}
          element={<HardwaresDetailPage />}
        />
        <Route history={history} path={routes.privateWireless} element={<PrivateWireless />} />
      </Routes>
      <Affirm />
    </BaseLayout>
  );
};

AppRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AppRouter;
