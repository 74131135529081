import './plans-screen.scss';
import { plans } from '../../../data/meta-data';
import routes from '../../../navigation/routes';
import NavigationBack from '../../components/ui-component/navigation-back/navigation-back';
import Plans from '../../components/ui-component/plans/plans';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TrustBadge from '../../components/main-page-section/trust-badge/trust-badge';
import CardBlock from '../../components/main-page-section/card-block/card-block';
import MapBlock from '../../components/main-page-section/map-block/map-block';
import ReviewsCarousel from '../../components/reviews-carousel/reviews-carousel';
import { PlanFilterPageType } from '../../../hooks/useFilteredPlansByPlaceType';

const PlansScreen = () => {
  useEffect(() => {
    document.body.scrollIntoView({
      block: 'start',
    });
  }, []);

  return (
    <div className="plans-screen-container">
      <Helmet>
        {plans.map(({ key, value }) => (
          <meta key={key} name={key} content={value} />
        ))}
      </Helmet>

      <section className="plans-screen">
        <NavigationBack className="plans-screen__nav-back" to={routes.home} />
        <h1 className="plans-screen__header">
          <span>NEW CUSTOMER OFFER:</span> SafeSim&trade; Plans
        </h1>
        <div className="plans-screen__sub">More Privacy. More Security. For Less</div>
        <div className="plans-screen__sub2">
          5G Included. 99% US Coverage. Move from any Carrier in under 10 mins.
        </div>
        <div className="plans-screen__cards-container">
          <Plans planPageType={PlanFilterPageType.PLANS} />
          <div className="plans-screen__oversea">
            * Extra charges apply for use of phone services overseas and domestic allowance is not
            included
          </div>
        </div>
      </section>
      <ReviewsCarousel />
      <TrustBadge />
      <CardBlock isPlanPage={true} />
      {/*<ReviewsBlock />*/}
      <MapBlock />
    </div>
  );
};
export default PlansScreen;
