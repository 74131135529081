/* eslint-disable max-len */
import phone from '../../../../assets/images/main-page/phone.png';
import phoneGo from '../../../../assets/images/main-page/phoneGo.png';
import React, { useMemo } from 'react';
import './card-block.scss';

const CardBlock = ({ isPlanPage, customData }) => {
  const items = useMemo(() => {
    if (customData?.items) {
      return customData.items;
    }

    return [
      {
        title: 'Your Security Solution',
        backgroundImage: phoneGo,
        items: [
          {
            title:
              'We provide you a mobile app with Anti Phishing and other features to help keep you safe on your mobile device*',
          },
          {
            title:
              'We provide you with additional account security to protect you from sim card hacking',
          },
          { title: 'We don’t sell or share your data' },
          {
            title:
              'We have sitewide SSL configuration and sophisticated cloud infrastructure for the highest security possible',
          },
        ],
      },
      {
        title: 'Nationwide Coverage & Fast Connectivity',
        backgroundImage: phone,
        items: [
          { title: '99% US coverage for your calls, data, and texts' },
          { title: 'GoTalk uses the largest and fastest 5G network in America' },
          { title: 'We offer 5G Ultra Capacity - have speed as fast as WiFi on your phone' },
        ],
      },
    ];
  }, [customData]);

  return (
    <section className={`card-block ${isPlanPage ? 'plan-page' : ''}`}>
      {isPlanPage ? (
        <h2 className="card-block__toptitle">{customData?.title || 'Why Go Talk Wireless?'}</h2>
      ) : null}
      {items.map((m, i) => {
        const isEven = i % 2 === 0;
        return (
          <div className={`card-block__item ${isEven ? 'card-block__item-reverse' : ''}`} key={i}>
            <div
              className={`card-block__information ${
                isEven ? 'card-block__information-reverse' : ''
              }`}
            >
              <h2 className={`card-block__title ${isEven ? 'card-block__title-reverse' : ''}`}>
                {m.title}
              </h2>
              <ul className="card-block__list">
                {m.items.map((bp, bpIdx) => (
                  <li className="card-block__list_item" key={bpIdx}>
                    <span className="card-block__list_circle" />
                    {bp.title}
                  </li>
                ))}
              </ul>
            </div>
            <div className="card-block__image">
              <img src={m.backgroundImage} alt="phone" className="card-block__image_item" />
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default CardBlock;
