import './landing-pages.scss';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Api from '../../../axios/api';
import { LANDING_PAGES } from '../../../axios/api-urls';
import Spinner from '../../components/ui-component/spinner/spinner';
import { Helmet } from 'react-helmet';
import routes from '../../../navigation/routes';
import NavigationBack from '../../components/ui-component/navigation-back/navigation-back';
import CardBlock from '../../components/main-page-section/card-block/card-block';
import MapBlock from '../../components/main-page-section/map-block/map-block';
import Plans from '../../components/ui-component/plans/plans';
import TrustBadge from '../../components/main-page-section/trust-badge/trust-badge';
import { ConversionTrackingActionNames } from '../../../types/conversionTrackingActionNames';
import { useDispatch } from 'react-redux';
import ReviewsCarousel from '../../components/reviews-carousel/reviews-carousel';
import { createDispatchLog } from '../../../redux/workers/conversion-tracking';
import { PlanFilterPageType } from '../../../hooks/useFilteredPlansByPlaceType';
import PrivateWirelessContact from '../../components/private-wireless/private-wireless-contact/private-wireless-contact';

const api = new Api();
export default function LandingPages() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.search) {
      const query = new URLSearchParams(location.search);
      if (query.get('whistle')) {
        dispatch(createDispatchLog(ConversionTrackingActionNames.WHISTLE_OUT));
        query.delete('whistle');
        navigate(`${location.pathname}${query.toString() || ''}`, {
          replace: true,
        });
      }
    }
  }, [location?.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, [params.slug]);

  function loadData() {
    if (!params.slug) {
      return;
    }

    setLoading(true);
    api
      .get(`${LANDING_PAGES.getPageByPageUrl}/${params.slug}`)
      .then((res) => setData(res.data.payload))
      .finally(() => setLoading(false));
  }

  if (!data || loading) {
    return (
      <section className="landing-page-container">
        <Spinner />
      </section>
    );
  }

  return (
    <>
      <Helmet>
        <title>{data.metaTitle}</title>
        <meta name="description" content={data.metaDescription} />
        <meta name="keywords" content={data.metaKeywords} />
      </Helmet>
      <section className="landing-page-container">
        <NavigationBack to={routes.home} />
        <h1 className="landing-page-container__header">{data.titleContent}</h1>
        <div className="landing-page-container__sub">{data.subtitleContent}</div>
        <div
          className="landing-page-container__content"
          dangerouslySetInnerHTML={{ __html: data.htmlContent }}
        />
        <div className="landing-page-container__plans">
          <Plans
            planPageType={PlanFilterPageType.LANDING}
            landingPageId={data.id}
            onlyShowLandingPagePlans={data.onlyShowLandingPagePlans}
            hideTabs={data.pageUrl === 'iot-plans'}
          />
          <div className="landing-page-container__oversea">
            * Extra charges apply for use of phone services overseas and domestic allowance is not
            included
          </div>
        </div>
      </section>
      {!data.hideReviews ? <ReviewsCarousel /> : null}
      <TrustBadge />
      <CardBlock customData={data.contentJson?.cardBlock} isPlanPage={true} />
      {data.showContactUsSection ? <PrivateWirelessContact /> : null}
      {!data.hideCoverageMap ? <MapBlock /> : null}
    </>
  );
}
