import './private-wireless-protect.scss';
import IosProtectImage from '../../../../assets/images/private-wireless/ios-protect.png';
import VpnIcon from '../../../../assets/images/private-wireless/vpn.svg';
import AntiPhishingIcon from '../../../../assets/images/private-wireless/anti-phishing.svg';
import IdTheftIcon from '../../../../assets/images/private-wireless/id-theft.svg';

export default function PrivateWirelessProtect() {
  const protectList = [
    {
      title: 'Anti-phishing',
      content:
        'Anti-phishing tools protect you from fraudulent websites that try to steal your personal information',
      icon: AntiPhishingIcon,
    },
    {
      title: 'ID Theft protection',
      content:
        'Secure your personal information and prevent others from using it for fraudulent purposes',
      icon: IdTheftIcon,
    },
  ];

  return (
    <div className="pwt-protect">
      <div className="pwt-protect--left-side">
        <div className="pwt-protect--title">
          PROTECT YOUR STAFF AND BUSINESS WITH <span>GO TALK</span> WIRELESS
        </div>
        <div className="pwt-protect--list">
          {protectList.map((m, i) => (
            <div className="pwt-protect--list-item" key={i.toString()}>
              <img src={m.icon} alt={m.title} className="pwt-protect--list-item-icon" />
              <div className="pwt-protect--list-item-title">{m.title}</div>
              <div className="pwt-protect--list-item-content">{m.content}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="pwt-protect--right-side">
        <img src={IosProtectImage} alt="Protect" className="pwt-protect--banner" />
      </div>
    </div>
  );
}
