import './plan-card.scss';
import messageVectorIndigo from '../../../../assets/images/icons/message-vector-indigo.svg';
import messageVectorWhite from '../../../../assets/images/icons/message-vector-white.svg';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUnlimited } from '../../../../utils/utils';

export default function PlanCard({ plan, disable, onBuyClick }) {
  const [learnMoreActive, setLearnMoreActive] = useState(false);
  const isModeBusiness = useSelector((state) => state.mainReducer.isModeBusiness);
  const isUnlimitedPlan = isUnlimited(plan.SMSCount) && isUnlimited(plan.minuteCount);
  const hasSmsMinute = plan.planType === 'DEFAULT';

  const infoList = useMemo(() => {
    let output = [];

    if (hasSmsMinute && !plan.isIotPlan) {
      if (isUnlimitedPlan) {
        output.push({ type: 'vpn', text: `<span>Unlimited</span> Minutes and SMS` });
      } else {
        output.push({
          type: 'vpn',
          text: `<span>${plan.minuteCount}</span> Minutes and <span>${plan.SMSCount}</span> SMS`,
        });
      }
    }

    if (plan.costBuyPlan >= 30) {
      output = [
        ...output,
        { type: 'vpn', text: 'Anti-Phishing tools' },
        { type: 'vpn', text: 'ID Theft Protection' },
      ];
    }

    return [
      ...output,
      ...plan.props?.info
        .filter((m) => m)
        .map((m) => {
          if (m.trim().toLowerCase() === 'use your phone overseas') {
            return {
              type: 'info',
              text: `${m}*`,
            };
          }
          return {
            type: 'info',
            text: m,
          };
        }),
    ];
  }, [plan.id, isUnlimitedPlan, hasSmsMinute]);

  const filteredInfoList = useMemo(() => {
    let output = [];

    if (!learnMoreActive) {
      output = infoList.slice(0, 4);
    } else {
      output = [...infoList];
    }

    return output;
  }, [infoList, learnMoreActive]);

  const price = useMemo(() => {
    return plan.hasDiscount ? plan.discountedPrice : plan.costBuyPlan;
  }, [plan.id]);

  const discountPercentage = useMemo(() => {
    if (!plan.hasDiscount) {
      return 0;
    }

    return ((plan.costBuyPlan - plan.discountedPrice) / plan.costBuyPlan) * 100;
  }, [plan.id]);

  if (isModeBusiness !== plan.isCompany) {
    return null;
  }

  return (
    <div
      className={`plan-card ${disable ? 'disable' : ''} ${plan.darkenBackground ? 'darken' : ''}`}
    >
      <div
        className="plan-card__title"
        dangerouslySetInnerHTML={{
          __html: plan.name,
        }}
      />

      {plan.slogan && <div className="plan-card__slogan">{plan.slogan}</div>}

      <div className="plan-card__price">
        <div className="plan-card__price-row">
          <div className="plan-card__price-unit">$</div>
          <div className="plan-card__price-cost">{price}</div>
          <div className="plan-card__price-monthly">
            <div className="plan-card__price-monthly--text">
              Monthly <br />
              Per User
            </div>
            {plan.hasDiscount && (
              <div className="plan-card__price-monthly--pill">
                SAVE {discountPercentage.toFixed(2).replace(/\.00$/, '')}%
              </div>
            )}
          </div>
        </div>
        {plan.hasMinMonthLimit && (
          <div className="plan-card__price-min-month">
            ${price * plan.minMonthLimit} for the first {plan.minMonthLimit} months
          </div>
        )}
      </div>

      <div className="plan-card__props">
        <div className="plan-card__props__title">We offer you:</div>
        {filteredInfoList.map((item, idx) => (
          <React.Fragment key={idx}>
            <div className="plan-card__props__item">
              <div className="plan-card__props__item__icon">
                <img src={messageVectorIndigo} />
                <img src={messageVectorWhite} className="hover" />
              </div>
              <div
                className="plan-card__props__item__text"
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              />
            </div>
            {idx === 3 && learnMoreActive && <div className="plan-card__props__plus">Plus</div>}
          </React.Fragment>
        ))}
        {learnMoreActive && (
          <div className="plan-card__props__asterisk">
            <b>*</b> Extra charges apply for use of phone services overseas and domestic allowance
            is not included
          </div>
        )}
      </div>
      <div className="plan-card__flex" />
      <div className="plan-card__buttons">
        <div
          className="plan-card__buttons-button outline"
          onClick={() => setLearnMoreActive((a) => !a)}
        >
          {learnMoreActive ? 'Back' : 'Learn more'}
        </div>

        <div className="plan-card__buttons-button primary" onClick={() => onBuyClick()}>
          Buy now
        </div>
      </div>

      <div className="plan-card__data">
        <div
          className={`plan-card__data__text ${
            plan.internetCount.value?.toString().length >= 3 ? 'small' : ''
          }`}
        >
          <b>
            {plan.internetCount.value}
            <small>{plan.internetCount.unit}</small>
          </b>
          <span>4G / 5G Data</span>
        </div>
        <img src={messageVectorIndigo} />
      </div>
    </div>
  );
}
